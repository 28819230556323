import React, { Component } from "react";
import "./Loading.scss";

export default class Loading extends Component {
  state = {
    textBefore: "<",
    textAfter: "/>",
    blinking: true
  };

  componentDidMount() {
    this.writeText(this.props.writeText);
  }

  writeText({
    text,
    time = 100,
    delay = 0,
    timeout,
    timeoutHandler,
    keepBlinking = false
  }) {
    if (!text) return null;

    for (let i = 0; i < text.length; i++) {
      const letter = text[i];
      setTimeout(() => {
        this.setState(prevState => ({
          textBefore: prevState.textBefore + letter
        }));
      }, i * time + delay);
    }

    const totalWait = text.length * time + delay + timeout;

    return timeout && timeoutHandler
      ? setTimeout(() => {
          timeoutHandler();
          this.setState({ blinking: keepBlinking });
        }, totalWait)
      : null;
  }

  render() {
    return (
      <div className="loading-text-wrapper">
        <p className="text-inner">
          <span>{this.state.textBefore}</span>
          {this.state.blinking && <span className="blinking">_</span>}
          <span> {this.state.textAfter}</span>
        </p>
      </div>
    );
  }
}
