import React, { useState } from "react";

import "./App.css";
import Loading from "./components/Loading/Loading";
import Welcome from "./components/Welcome/Welcome";
import Header from "./components/Header/Header";

function App() {
  const [loading, setLoading] = useState(true);

  return (
    <div className="App">
      <div className="main container">
        <Header done={!loading}>
          <Loading
            writeText={{
              text: "Firas",
              time: 150,
              delay: 1000,
              timeout: 1500,
              timeoutHandler: () => setLoading(false),
              keepBlinking: true
            }}
          />
        </Header>

        {!loading && <Welcome />}
      </div>
    </div>
  );
}

export default App;
